<template>
 <div class="tooltip" :class="{ 'show-tooltip': show }">
  <slot></slot>
  <div class="tooltip-content capitalize">{{ content }}</div>
 </div>
</template>

<script>
export default {
 props: {
  show: {
   type: Boolean,
   required: true,
  },
  content: {
   type: String,
   required: true,
  },
 },
 data() {
  return {};
 },
};
</script>

<style scoped>
.tooltip {
 position: fixed;
 display: inline-block;
 border-bottom: 1px dotted black;
}

.tooltip-content {
 visibility: hidden;
 width: 160px;
 text-align: center;
 border-radius: 6px;
 padding: 5px 0;
 position: absolute;
 z-index: 1;
 top: -5px;
 left: 40px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 /* transition: opacity 0.1s ease, visibility 0.1s ease; */
 font-size: 13px;
 background-color: rgb(63 63 70);
 color: rgb(248 250 252);
}

.tooltip-content::before {
 content: "";
 position: absolute;
 top: 50%;
 right: 100%;
 margin-top: -5px;
 border-width: 5px;
 border-style: solid;
 border-color: transparent rgb(63 63 70) transparent transparent;
}

.show-tooltip .tooltip-content {
 opacity: 1;
 visibility: visible;
}
</style>
