<template>
 <div
  v-show="
   this.$route.name !== 'Login' &&
   this.$route.name !== 'ResetPassword' &&
   filteredArray &&
   !closeAnnouncementBanner
  "
  class="pointer-events-none z-50 fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
 >
  <div
   class="pointer-events-auto flex items-center justify-between gap-x-6 encom_primary px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5"
  >
   <p class="text-sm leading-6 text-white">
    <a href="/customer-files" @click="saveToLocalStorage(true)">
     <strong class="font-semibold">
      <span class="mr-1">
       <font-awesome-icon
        :icon="['far', 'bell']"
        class="h-4 w-4 text-white fa-shake"
        aria-hidden="true"
        style="--fa-animation-duration: 2s"
       />
      </span>

      Attention requise !
     </strong>
     <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
      <circle cx="1" cy="1" r="1" /></svg
     >Une collecte est en attente pour que nous puissions procéder à l'installation de vos
     services.&nbsp;<span aria-hidden="true">&rarr;</span>
    </a>
   </p>
   <button @click="saveToLocalStorage(true)" type="button" class="-m-1.5 flex-none p-1.5 tooltip">
    <span class="sr-only">Dismiss</span>
    <svg
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 20"
     fill="currentColor"
     class="h-5 w-5 text-white"
    >
     <path
      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
     />
    </svg>
    <p class="text-center text-sm tooltiptext">
     {{ $t("hideAnnouncement") }}
    </p>
   </button>
  </div>
 </div>
</template>

<script>
import axios from "axios";
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

const closeAnnouncementBanner = !sessionStorage.getItem("closeAnnouncementBanner")
 ? false
 : sessionStorage.getItem("closeAnnouncementBanner");

export default {
 props: [""],
 components: {},
 data() {
  return { account, filteredArray: false, closeAnnouncementBanner, userPermissions };
 },
 created() {},
 methods: {
  async getData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections?customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    let files = res.data.data;
    if (files.length > 0) this.filteredArray = files.some((element) => element.status === "open");
   } catch (error) {
    console.error(error.response);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  saveToLocalStorage(bool) {
   this.closeAnnouncementBanner = bool;
   sessionStorage.setItem("closeAnnouncementBanner", bool);
  },
 },

 mounted() {
  if (
   this.userPermissions &&
   this.userPermissions.permissions &&
   (this.userPermissions.permissions.includes("information-collections.*") ||
    this.userPermissions.permissions.includes("information-collections.view") ||
    this.userPermissions.permissions.includes("information-collections.edit"))
  ) {
   this.getData();
  }
 },
 computed: {},
};
</script>
<style scoped>
.tooltip {
 position: relative;
 display: inline-block;
}

.tooltip .tooltiptext {
 visibility: hidden;
 width: 150px;
 background-color: #111827;
 color: #fff;
 text-align: center;
 border-radius: 6px;
 padding: 5px;
 position: absolute;
 z-index: 1;
 bottom: 120%;
 left: 100%;
 margin-left: -92px;
}

.tooltip .tooltiptext::after {
 content: "";
 position: absolute;
 top: 100%;
 left: 50%;
 margin-left: -5px;
 border-width: 5px;
 border-style: solid;
 border-color: #111827 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
 visibility: visible;
}
</style>
