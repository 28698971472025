<template>
 <!--
      When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars
  
      Menu open: "fixed inset-0 z-40 overflow-y-auto", Menu closed: ""
      -->
 <div
  class="fixed top-0 hidden w-24 sidebar overflow-y-auto overflow-x-hidden md:block border-r">
  <div class="w-full flex flex-col items-center gap-2 xl:space-y-2">
   <div class="flex-shrink-0 flex items-center py-2">
    <img v-if="!isGuest" class="h-10 w-auto" :src="logo" alt="Workflow" />
   </div>
   <div class="flex-1 w-2/3 px-1 xl:space-y-1">
    <router-link
     @click="$emit('openSideNavHover', item.name), $emit('closeTicketDetail')"
     v-for="(item, index) in navigation"
     :key="item.name"
     :to="item.href ? item.href : ''"
     v-show="
      item.show &&
      (userPermissions.indexOf(`${item.permissionName}.*`) >= 0 ||
       userPermissions.indexOf(`${item.permissionName}.view-own`) >= 0 ||
       userPermissions.indexOf(`${item.permissionName}.view`) >= 0)
     "
     @mouseenter="setHovered(index, true)"
     @mouseleave="setHovered(index, false)"
     :class="[
      item.route && this.$route?.name === item.route
       ? 'sidebar__menu--active sidebar__text--active'
       : 'sidebar__menu--hover sidebar__text--hover text-green-700 ',
      'group w-full p-3 rounded-md flex flex-col items-center text-2xl font-medium cursor-pointer relative',
     ]"
     :aria-current="item.current ? 'page' : undefined">
     <font-awesome-icon :icon="item.icon" class="icon alt" />
     <span
      v-show="item.beta"
      class="absolute -top-2 opacity-80 inline-flex items-center rounded-md bg-yellow-50 px-1 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700 ring-opacity-20">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 20 20"
       fill="currentColor"
       class="w-2 h-2 mr-1">
       <path
        d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
      </svg>
      {{ $t("beta") }}
     </span>
     <TooltipComponent :content="$t(item.name)" :show="hoveredLinks[index]">
     </TooltipComponent>
    </router-link>
   </div>
   <div
    class="border-t border-gray-400 border-opacity-50 flex-1 xl:pt-4 xl:space-y-2">
    <router-link
     @click="$emit('openSideNavHover', item.name)"
     v-for="(item, index) in specialNavigation"
     :key="item.name"
     :to="item.href"
     v-show="
      item.show &&
      (userPermissions.includes(`${item.permissionName}.*`) ||
       userPermissions.includes(`${item.permissionName}.view-own`) ||
       userPermissions.includes(`${item.permissionName}.view`))
     "
     @mouseenter="setSpecialHovered(index, true)"
     @mouseleave="setSpecialHovered(index, false)"
     :class="[
      item.route && this.$route?.name?.includes(item.route)
       ? 'sidebar__menu--active sidebar__text--active'
       : 'sidebar__menu--hover sidebar__text--hover sidebar__text',
      'group w-full p-3 rounded-md flex flex-col items-center text-2xl font-medium',
     ]"
     :aria-current="item.current ? 'page' : undefined">
     <font-awesome-icon :icon="item.icon" class="icon alt" />
     <TooltipComponent
      :content="$t(item.name)"
      :show="hoveredSpecialLinks[index]">
     </TooltipComponent>
    </router-link>
   </div>
   <div class="flex-1 px-1">
    <a
     href="https://sms.encom.tel"
     target="_blank"
     @mouseenter="setSpecialHovered(6, true)"
     @mouseleave="setSpecialHovered(6, false)"
     class="group sidebar__menu--hover sidebar__text--hover w-full xl:p-3 rounded-md flex flex-col items-center text-2xl font-medium cursor-pointer sidebar__text">
     <font-awesome-icon icon="sms" class="icon alt" />
     <TooltipComponent content="Plateforme SMS" :show="hoveredSpecialLinks[6]">
     </TooltipComponent>
    </a>
   </div>
  </div>
  <div
   class="sandbox_view text-xs mx-0 w-full items-center flex-shrink-0 pt-1 px-1">
   <div
    v-if="
     userPermissions.includes(`expert-statistics.*`) ||
     userPermissions.includes(`expert-statistics.view-own`) ||
     userPermissions.includes(`expert-statistics.view`)
    "
    ref="menu_config"
    class="flex-1 w-full justify-center items-center">
    <div as="div" class="mr">
     <div>
      <button
       @click="$router.push('/expert-statistics/configuration/groups')"
       class="sidebar__menu--hover mb-1 sidebar__text--hover text-gray-600 group text-center xl:p-3 rounded-md flex justify-center flex-col mx-auto items-center text-2xl font-semibold">
       <font-awesome-icon icon="gear" class="icon alt" />
      </button>
     </div>
     <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <div v-if="showConfigMenu" class="config_menu">
       <div class="py-1 config-content divide-y">
        <div
         v-for="link in configMenuLinks"
         :key="link.name"
         class="rounded mx-1 px-2.5 py-1.5 text-sm">
         <router-link
          :to="link.href"
          :class="['group flex px-4 py-2 text-sm text-gray-700']">
          {{ $t(link.name) }}
         </router-link>
        </div>
       </div>
      </div>
     </transition>
    </div>
    <!-- <button
     @click="$emit('openFeedback', true)"
     class="sidebar__menu--hover mb-1 sidebar__text--hover text-gray-600 group text-center p-3 rounded-md flex justify-center flex-col mx-auto items-center text-2xl font-semibold">
     <font-awesome-icon icon="gear" class="icon alt" />
    </button> -->
   </div>
   <div class="flex-1 w-full">
    <button
     @click="$emit('openFeedback', true)"
     class="sidebar__menu--hover mb-1 sidebar__text--hover encom_pink_text group w-full p-3 rounded-md flex flex-col items-center text-2xl font-semibold">
     <font-awesome-icon icon="comment-plus" class="icon alt" />

     <span class="mt-2 text-xs text-center">{{ $t("header.feedback") }}</span>
    </button>
   </div>
   <div class="border-t border-gray-300">
    <p class="font-medium sidebar__text"><span>&copy;</span> CX-Engine</p>

    <p class="font-medium sidebar__text capitalize">
     ({{ sandbox === "true" ? $t("bannerSandbox") : $t("bannerProduction") }})
    </p>
   </div>
  </div>
 </div>

 <!-- Mobile menu -->
 <TransitionRoot as="template" :show="mobileMenuOpen">
  <Dialog as="div" class="md:hidden" @close="$emit('closeSubmenu')">
   <div class="fixed inset-0 z-40 flex">
    <TransitionChild
     as="template"
     enter="transition-opacity ease-linear duration-300"
     enter-from="opacity-0"
     enter-to="opacity-100"
     leave="transition-opacity ease-linear duration-300"
     leave-from="opacity-100"
     leave-to="opacity-0">
     <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
    </TransitionChild>
    <TransitionChild
     as="template"
     enter="transition ease-in-out duration-300 transform"
     enter-from="-translate-x-full"
     enter-to="translate-x-0"
     leave="transition ease-in-out duration-300 transform"
     leave-from="translate-x-0"
     leave-to="-translate-x-full">
     <div
      class="relative max-w-xs w-full sidebar pt-5 pb-4 flex-1 flex flex-col">
      <TransitionChild
       as="template"
       enter="ease-in-out duration-300"
       enter-from="opacity-0"
       enter-to="opacity-100"
       leave="ease-in-out duration-300"
       leave-from="opacity-100"
       leave-to="opacity-0">
       <div class="absolute top-1 right-0 -mr-14 p-1">
        <button
         type="button"
         class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
         @click="$emit('closeSubmenu')">
         <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
         <span class="sr-only">Close sidebar</span>
        </button>
       </div>
      </TransitionChild>
      <div class="flex-shrink-0 px-4 flex items-center">
       <img class="h-8 w-auto" :src="logo" alt="Workflow" />
      </div>
      <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
       <nav aria-label="Sidebar" :class="`sticky top-4 divide-malachite-light`">
        <template v-for="item in navigation" :key="item.name">
         <div v-if="!item.children" v-show="item.show">
          <div class="pb-2 space-y-1">
           <router-link
            v-show="item.href"
            :to="item.children ? '' : item.href"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active text-white`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
            ]"
            :aria-current="item.current ? 'page' : undefined">
            <font-awesome-icon :icon="item.icon" class="icon alt" />
            <span class="capitalize ml-2">{{ $t(`${item.name}`) }}</span>
           </router-link>
           <a
            v-show="!item.href"
            href="#"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active text-white`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
            ]">
            <font-awesome-icon :icon="item.icon" class="icon alt" />
            <span class="ml-2 capitalize">{{ $t(`${item.name}`) }}</span>
           </a>
          </div>
         </div>
         <Disclosure
          v-show="item.show"
          as="div"
          v-else
          class="space-y-1 pb-2"
          v-slot="{ open }">
          <router-link
           :to="item.children ? '' : item.href"
           :class="[
            item.href === this.$route.path
             ? `sidebar__menu--active text-white`
             : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
            ` flex items-center px-0 py-0 text-sm leading-6 font-medium `,
           ]"
           :aria-current="item.current ? 'page' : undefined">
           <DisclosureButton
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active text-white`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group w-full flex items-center px-2 py-2 text-left text-sm leading-6 font-medium `,
            ]">
            <font-awesome-icon :icon="item.icon" class="icon alt" />

            <span class="flex-1 capitalize ml-2">
             {{ $t(`${item.name}`) }}
            </span>
            <svg
             :class="[
              open ? 'text-gray-400 rotate-90' : 'text-gray-300',
              'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
             ]"
             viewBox="0 0 20 20"
             aria-hidden="true">
             <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
           </DisclosureButton>
          </router-link>

          <DisclosurePanel class="space-y-1">
           <router-link
            v-for="subItem in item.children"
            :key="subItem.name"
            :to="subItem.href"
            :class="[
             subItem.href === this.$route.path
              ? `sidebar__menu--active text-white`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center py-1 text-sm leading-6 font-medium `,
            ]">
            <span class="px-3">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
               fill-rule="evenodd"
               d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
               clip-rule="evenodd" />
             </svg>
            </span>
            <span class="px-0 capitalize">{{ $t(`${subItem.name}`) }}</span>
           </router-link>
          </DisclosurePanel>
         </Disclosure>
        </template>
        <hr />
        <div class="pt-5 pb-5" v-show="this.showSpecialNavigation">
         <p
          class="px-3 text-xs sidebar__text font-bold uppercase tracking-wider pb-2"
          id="communities-headline"></p>
         <template v-for="item in specialNavigation" :key="item.name">
          <div v-if="!item.children">
           <div class="pb-2 space-y-1">
            <router-link
             v-show="item.href"
             :to="item.href"
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group flex items-center px-2 py-2 text-sm leading-6 font-medium`,
             ]"
             :aria-current="item.current ? 'page' : undefined">
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->
             <span class="capitalize">
              {{ $t(`${item.name}`) }}
             </span>
            </router-link>
            <a
             v-show="!item.href"
             href="#"
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
             ]">
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->
             <span> {{ $t(`${item.name}`) }} </span>
            </a>
           </div>
          </div>
          <Disclosure as="div" v-else class="space-y-1 pb-2" v-slot="{ open }">
           <router-link
            :to="item.children ? '' : item.href"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active text-white`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             ` flex items-center px-0 py-0 text-sm leading-6 font-medium `,
            ]"
            :aria-current="item.current ? 'page' : undefined">
            <DisclosureButton
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active text-white`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group w-full flex items-center px-2 py-2 text-left text-sm leading-6 font-medium `,
             ]">
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->

             <span class="flex-1 uppercase">
              {{ $t(`${item.name}`) }}
             </span>
             <svg
              :class="[
               open ? 'text-gray-400 rotate-90' : 'text-gray-300',
               'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
              ]"
              viewBox="0 0 20 20"
              aria-hidden="true">
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
             </svg>
            </DisclosureButton>
           </router-link>

           <DisclosurePanel class="space-y-1">
            <template v-for="subItem in item.children" :key="subItem.name">
             <Disclosure as="div" class="space-y-1 pb-2" v-slot="{ open }">
              <router-link
               :to="subItem.children ? '' : subItem.href"
               :class="[
                subItem.href === this.$route.path
                 ? `sidebar__menu--active text-white`
                 : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
                ` flex items-center px-0 py-0 text-xs leading-6 font-normal `,
               ]"
               :aria-current="subItem.current ? 'page' : undefined">
               <DisclosureButton
                :class="[
                 subItem.href === this.$route.path
                  ? `sidebar__menu--active text-white`
                  : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
                 `group w-full flex items-center ml-2 px-2 py-2 text-left text-xs leading-2 font-normal `,
                ]">
                <!-- <component
                  :is="subItem.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->

                <span class="flex-1 uppercase">
                 {{ $t(`${subItem.name}`) }}
                </span>
                <svg
                 v-if="subItem.children"
                 :class="[
                  open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                  'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                 ]"
                 viewBox="0 0 20 20"
                 aria-hidden="true">
                 <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
               </DisclosureButton>
              </router-link>

              <DisclosurePanel v-if="subItem.children" class="space-y-1">
               <router-link
                v-for="subItem in subItem.children"
                :key="subItem.name"
                :to="subItem.href"
                :class="[
                 subItem.href === this.$route.path
                  ? `sidebar__menu--active text-white`
                  : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
                 `group flex items-center py-1 text-sm leading-6 font-medium `,
                ]">
                <span class="px-3">
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                   fill-rule="evenodd"
                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                   clip-rule="evenodd" />
                 </svg>
                </span>
                <span class="px-0 capitalize">{{ $t(`${subItem.name}`) }}</span>
               </router-link>
              </DisclosurePanel>
             </Disclosure>
            </template>
           </DisclosurePanel>
          </Disclosure>
         </template>
        </div>
        <div class="pt-0">
         <div class="mt-0 space-y-2" aria-labelledby="communities-headline">
          <a
           v-for="item in secondaryNavigation"
           :key="item.name"
           :href="item.href"
           :class="[
            `sidebar__menu--active_text sidebar__menu--hover group flex items-center px-2 py-2 text-sm leading-6 font-medium  sidebar__text--hover`,
           ]">
           {{ $t(`${item.name}`) }}
          </a>
         </div>
        </div>
       </nav>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-2">
       <button
        v-if="adminToken"
        @click="leaveImpersonation()"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_closed_ticket_button font-medium text-white focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">
        {{ $t("header.leaveImpersonate") }}
       </button>
       <a
        @click="signOut()"
        href="#"
        class="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mr-3 sm:w-auto sm:text-sm">
        {{ $t("header.logout") }}
       </a>
      </div>
     </div>
    </TransitionChild>

    <div class="flex-shrink-0 w-14" aria-hidden="true">
     <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
   </div>
  </Dialog>
 </TransitionRoot>
</template>

<script>
import { HomeIcon, ClockIcon, MailIcon, XIcon } from "@heroicons/vue/outline";
import {
 Dialog,
 DialogOverlay,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 TransitionChild,
 TransitionRoot,
 Disclosure,
 DisclosureButton,
 DisclosurePanel,
} from "@headlessui/vue";
import TooltipComponent from "./TooltipComponent.vue";
import { mapGetters } from "vuex/dist/vuex.cjs.js";

const sandbox = localStorage.getItem("sandbox");
const logo = localStorage.getItem("logo");
const adminToken = localStorage.getItem("adminToken");

const configMenuLinks = [
 {
  name: "expert-statistics",
  href: "/expert-statistics/configuration/personal",
 },
];

export default {
 name: "Sidebar",
 props: [
  "navigation",
  "secondaryNavigation",
  "specialNavigation",
  "showSpecialNavigation",
  "statisticsAvailableForProduction",
  "hasChildren",
  "openSubmenu",
  "userPermissions",
 ],
 components: {
  HomeIcon,
  ClockIcon,
  MailIcon,
  XIcon,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  TooltipComponent,
 },
 data() {
  return {
   configMenuLinks,
   showConfigMenu: false,
   sandbox,
   mobileMenuOpen: false,
   hoveredLinks: [],
   hoveredSpecialLinks: [],
   logo,
   adminToken,
   version: "",
  };
 },
 created() {
  const pkg = require("../../package.json");
  this.version = pkg.version;
 },
 mounted() {
  document.addEventListener("click", this.handleClickOutside);
 },
 unmounted() {
  document.removeEventListener("click", this.handleClickOutside);
 },
 methods: {
  handleClickOutside(event) {
   if (
    this.$refs.menu_config &&
    !this.$refs.menu_config.contains(event.target)
   ) {
    this.showConfigMenu = false;
   }
  },
  setHovered(index, isHovered) {
   this.hoveredLinks[index] = isHovered;
  },
  setSpecialHovered(index, isHovered) {
   this.hoveredSpecialLinks[index] = isHovered;
  },
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("accountName");
   this.$store.dispatch("user", {});
   this.$store.dispatch("admin", false);
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
  async leaveImpersonation() {
   try {
    this.$router.push("/");
    localStorage.setItem("token", this.adminToken);
    this.$store.dispatch("account", "");
    this.$store.dispatch("token", this.adminToken);
    localStorage.removeItem("account");
    impersonatePopup = false;
   } catch (error) {
    impersonatePopup = false;
    this.errorHandling(error);
   } finally {
    location.reload();
   }
  },
 },
 watch: {
  openSubmenu: function (val) {
   this.mobileMenuOpen = val;
  },
 },
 computed: {
  ...mapGetters(["isGuest"]),
 },
};
</script>

<style>
.config_menu {
 position: fixed;
}

.config-content {
 /* visibility: hidden; */
 width: 200px;
 /* text-align: center; */
 position: absolute;
 z-index: 1;
 bottom: 0;
 left: 70px;
 border-radius: 6px;
 padding: 5px 0;
 /* transition: opacity 0.1s ease, visibility 0.1s ease; */
 background-color: #fff;
 color: rgb(248 250 252);
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.config-content::before {
 content: "";
 position: absolute;
}
.sandbox_view {
 bottom: 0;
 position: absolute;
}
.logout_impersonate_buttons {
 position: relative;
 bottom: 0;
}
.icon-spin {
 animation: spin 2s linear infinite;
}

@keyframes spin {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(45deg);
 }
}
</style>
