export default {
  data() {
    return {};
  },
  methods: {
    _throw(err) {
      throw err;
    },
  },
};
