export default {
 data() {
  return {
   email: "",
  };
 },
 methods: {
  emailValidator(email) {
   if (email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
   }
   return false;
  },
 },
};
