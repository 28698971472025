<template>
 <div class="text-overlay text-teal-600 font-medium">
  <div>
   <p class="text-lg font-bold">{{ title }}</p>
   <p class="text-md">{{ text }}</p>
   <p class="text-md">Merci pour votre patience !</p>
  </div>
 </div>
</template>
<script>
export default {
 props: ["text", "title"],
};
</script>
<style lang=""></style>
