export default {
 data() {
  return {};
 },
 methods: {
  collectForms() {
   const keys = {
    mainInfos: this.$t("mainInfos"),
    numbers: this.$t("numbers"),
    users: this.$t("users"),
    callQueue: this.$t("callQueue"),
    svi: this.$t("svi"),
    studio: this.$t("studio"),
    groups: this.$t("groups"),
    network: this.$t("network"),
    relations: this.$t("relations"),
   };
   return keys;
  },
  checkForElementInDependencies(data, depententKeys, userExtensionToDelete) {
   let content = data;
   let hasElementArray = [];
   let keysFromdepententKeys = Object.keys(depententKeys);

   if (keysFromdepententKeys.length > 0) {
    for (let index = 0; index < keysFromdepententKeys.length; index++) {
     const element = keysFromdepententKeys[index];

     let elementKeys = Object.keys(depententKeys[element]);

     if (Object.keys(content).indexOf(element) >= 0) {
      for (let indexElementKeys = 0; indexElementKeys < elementKeys.length; indexElementKeys++) {
       const elementForElementKeys = elementKeys[indexElementKeys];
       let keyToCheckForDependencie = depententKeys[element][elementForElementKeys];

       let dataToCheck = content[element];
       if (keyToCheckForDependencie["isArray"] && !keyToCheckForDependencie["hasObject"]) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck = dataToCheck[indexDataToCheck][elementForElementKeys];
         let value = undefined;
         for (
          let indexElementDataToCheck = 0;
          indexElementDataToCheck < elementDataToCheck.length;
          indexElementDataToCheck++
         ) {
          const elementElementDataToCheck = elementDataToCheck[indexElementDataToCheck];

          if (elementElementDataToCheck && String(elementElementDataToCheck.includes("-"))) {
           value = elementElementDataToCheck.split("-");
           value = value[0].replace(/\s+/g, "");
          } else {
           value = elementElementDataToCheck;
          }
          if (parseInt(userExtensionToDelete) == parseInt(value)) {
           hasElementArray.indexOf(element) < 0 ? hasElementArray.push(element) : "";
          }
         }
        }
       } else if (keyToCheckForDependencie["isArray"] && keyToCheckForDependencie["hasObject"]) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck = dataToCheck[indexDataToCheck][elementForElementKeys];
         let value = undefined;
         for (
          let indexElementDataToCheck = 0;
          indexElementDataToCheck < elementDataToCheck.length;
          indexElementDataToCheck++
         ) {
          const elementElementDataToCheck =
           elementDataToCheck[indexElementDataToCheck][keyToCheckForDependencie["valueKeyToCheck"]];

          value = elementElementDataToCheck;
          if (parseInt(userExtensionToDelete) == parseInt(value)) {
           hasElementArray.indexOf(element) < 0 ? hasElementArray.push(element) : "";
          }
         }
        }
       } else if (
        keyToCheckForDependencie["isObject"] &&
        keyToCheckForDependencie["valueKeyToCheck"]
       ) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck =
          dataToCheck[indexDataToCheck][elementForElementKeys][
           keyToCheckForDependencie["valueKeyToCheck"]
          ];
         let value = undefined;

         if (elementDataToCheck && elementDataToCheck.includes("-")) {
          value = elementDataToCheck.split("-");
          value = value[0].replace(/\s+/g, "");
         } else {
          value = elementDataToCheck;
         }
         if (parseInt(userExtensionToDelete) == parseInt(value)) {
          hasElementArray.indexOf(element) < 0 ? hasElementArray.push(element) : "";
         }
        }
       }
      }
     }
    }
   }
   return { content, hasElementArray };
  },
  checkForElementInDependenciesEdit(data, depententKeys, userExtensionToDelete, editIdx) {
   let content = data;
   let extensionValue = content["users"].users[editIdx]["userSettings"]["internalNumber"];
   let extensionName =
    content["users"].users[editIdx]["userSettings"]["firstName"] +
    " " +
    content["users"].users[editIdx]["userSettings"]["lastName"];
   let newValue = extensionValue + " " + "-" + " " + extensionName;
   let keysFromdepententKeys = Object.keys(depententKeys);

   if (keysFromdepententKeys.length > 0) {
    for (let index = 0; index < keysFromdepententKeys.length; index++) {
     const element = keysFromdepententKeys[index];
     let elementKeys = Object.keys(depententKeys[element]);
     if (Object.keys(content).indexOf(element) >= 0) {
      for (let indexElementKeys = 0; indexElementKeys < elementKeys.length; indexElementKeys++) {
       const elementForElementKeys = elementKeys[indexElementKeys];
       let keyToCheckForDependencie = depententKeys[element][elementForElementKeys];

       let dataToCheck = content[element];
       if (keyToCheckForDependencie["isArray"] && !keyToCheckForDependencie["hasObject"]) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck = dataToCheck[indexDataToCheck][elementForElementKeys];
         let value = undefined;
         for (
          let indexElementDataToCheck = 0;
          indexElementDataToCheck < elementDataToCheck.length;
          indexElementDataToCheck++
         ) {
          const elementElementDataToCheck = elementDataToCheck[indexElementDataToCheck];

          if (elementElementDataToCheck && String(elementElementDataToCheck.includes("-"))) {
           value = elementElementDataToCheck.split("-");
           value = value[0].replace(/\s+/g, "");
          } else {
           value = elementElementDataToCheck;
          }
          if (parseInt(userExtensionToDelete) == parseInt(value)) {
           content[element][indexDataToCheck][elementForElementKeys].splice(
            indexElementDataToCheck,
            1,
            newValue
           );
          }
         }
        }
       } else if (keyToCheckForDependencie["isArray"] && keyToCheckForDependencie["hasObject"]) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck = dataToCheck[indexDataToCheck][elementForElementKeys];
         let value = undefined;
         for (
          let indexElementDataToCheck = 0;
          indexElementDataToCheck < elementDataToCheck.length;
          indexElementDataToCheck++
         ) {
          const elementElementDataToCheck =
           elementDataToCheck[indexElementDataToCheck][keyToCheckForDependencie["valueKeyToCheck"]];

          value = elementElementDataToCheck;

          if (parseInt(userExtensionToDelete) == parseInt(value)) {
           content[element][indexDataToCheck][elementForElementKeys][indexElementDataToCheck][
            "extension"
           ] = extensionValue;
           content[element][indexDataToCheck][elementForElementKeys][indexElementDataToCheck][
            "extensionName"
           ] = extensionName;
          }
         }
        }
       } else if (
        keyToCheckForDependencie["isObject"] &&
        keyToCheckForDependencie["valueKeyToCheck"]
       ) {
        for (let indexDataToCheck = 0; indexDataToCheck < dataToCheck.length; indexDataToCheck++) {
         const elementDataToCheck =
          dataToCheck[indexDataToCheck][elementForElementKeys][
           keyToCheckForDependencie["valueKeyToCheck"]
          ];
         let value = undefined;

         if (elementDataToCheck && elementDataToCheck.includes("-")) {
          value = elementDataToCheck.split("-");
          value = value[0].replace(/\s+/g, "");
         } else {
          value = elementDataToCheck;
         }
         if (parseInt(userExtensionToDelete) == parseInt(value)) {
          content[element][indexDataToCheck][elementForElementKeys][
           keyToCheckForDependencie["valueKeyToCheck"]
          ] = newValue;
         }
        }
       }
      }
     }
    }
   }
   return content;
  },
 },
};
