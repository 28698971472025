export default {
  data() {
    return {};
  },
  methods: {
    rioRegexMatch(rio) {
      let valid = false;

      //let regex = /[A-Ea-e0-9][A-Za-z0-9][P-Z][A-Z0-9]{6}[A-Z0-9+]{3}/;

      //We need to check if the first char start by A to E for mobile number
      //or F to Z for landline number
      let regex = /(?:[A-Ea-e0-9]|[F-Zf-z0-9])[A-Za-z0-9][P-Z][A-Z0-9+]{6}[A-Z0-9+]{3}/;

      valid = regex.test(rio);
      /* console.log(
        "file: ValidForm.js ~ line 58 ~ rioRegexMatch ~ valid",
        valid
      ); */
      return valid;
    },
    validCCC(rio, number) {
      // console.log("file: ValidForm.js ~ line 62 ~ validCCC ~ rio", rio);
      let absoluteNumber = null;
      number.length === 11
        ? (absoluteNumber = 0 + number.slice(2, 12))
        : number.length === 13
        ? (absoluteNumber = 0 + number.slice(4, 13))
        : number.length === 12
        ? (absoluteNumber = 0 + number.slice(3, 12))
        : (absoluteNumber = number);

      let isValid = false;
      let OO = rio.slice(0, 2);
      let Q = rio.slice(2, 3);
      let RRRRRR = rio.slice(3, 9);
      let CCC = rio.slice(9, 12);
      let abc = "";
      let rioToUpperCase = rio.toUpperCase();
      let concatenation = rioToUpperCase.substr(0, 9) + absoluteNumber;
      /*  console.log(
        "file: ValidForm.js ~ line 69 ~ validCCC ~ concatenation",
        concatenation
      ); */
      let order = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+";
      let a = 0;
      let b = 0;
      let c = 0;
      for (let index = 0; index < concatenation.length; index++) {
        let position = order.indexOf(concatenation[index]);
        /*     console.log(
          "file: ValidForm.js ~ line 73 ~ validCCC ~ position",
          position
        ); */
        a = (1 * a + position) % 37;
        b = (2 * b + position) % 37;
        c = (4 * c + position) % 37;
      }
      abc = order[a] + order[b] + order[c];
      //console.log("file: ValidForm.js ~ line 91 ~ validCCC ~ CCC", CCC);
      //console.log("file: ValidForm.js ~ line 78 ~ validCCC ~ abc", abc);
      if (CCC === abc) {
        isValid = true;
      }
      return isValid;
    },
  },
};
