<template>
 <div class="relative">
  <select
   v-on:change="setLanguage($event)"
   v-model="$i18n.locale"
   class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 focus:ring-2 focus:ring-green-700 sm:text-sm sm:leading-6"
  >
   <option
    v-for="(lang, i) in $i18n.availableLocales"
    :key="`Lang${i}`"
    :value="lang.toLowerCase()"
   >
    {{ lang.toUpperCase() }}
   </option>
  </select>
  <div class="pointer-events-none absolute inset-y-0 right-9 flex items-center">
   <i
    :class="`em em-flag-${$i18n.locale === 'en' ? 'um' : $i18n.locale}`"
    aria-role="presentation"
    :aria-label="`${$i18n.locale === 'en' ? 'em' : $i18n.locale} flag`"
   ></i>
  </div>
 </div>
 <!-- <Menu as="div" class="relative inline-block text-left">
  <div>
   <MenuButton
    class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
   >
    <span class="sr-only">Open options</span>
    <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
   </MenuButton>
  </div>

  <transition
   enter-active-class="transition ease-out duration-100"
   enter-from-class="transform opacity-0 scale-95"
   enter-to-class="transform opacity-100 scale-100"
   leave-active-class="transition ease-in duration-75"
   leave-from-class="transform opacity-100 scale-100"
   leave-to-class="transform opacity-0 scale-95"
  >
   <MenuItems
    class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
   >
    <div class="py-1">
     <MenuItem v-for="(lang, i) in langs" :key="`lang-${i}`" as="template" v-slot="{ active }">
      <a
       href="#"
       :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
      >
       <span v-html="lang.flag"></span>{{ lang.value }}
      </a>
     </MenuItem>
    </div>
   </MenuItems>
  </transition>
 </Menu> -->
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

export default {
 name: "LanguageSelector",
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },
 data() {
  return {
   langs: ["EN", "FR"],
   newLangs: [
    { flag: "&#127482;&#127480;", value: "EN" },
    { flag: "&#127467;&#127479;", value: "FR" },
   ],
  };
 },
 computed: {},
 methods: {
  setLanguage(event) {
   this.$store.dispatch("language", event.target.value);
   localStorage.setItem("language", event.target.value);
  },
  getFlagEmoji(countryCode) {
   const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
   return String.fromCodePoint(...codePoints);
  },
 },
};
</script>

<style>
.lang-menu {
 margin-inline-start: -10px;
 width: auto;
 text-align: left;
 margin-top: 0px;
 position: relative;
}
.lang-menu .selected-lang {
 display: flex;
 justify-content: space-between;
 line-height: 1.6;
 cursor: pointer;
}
.lang-menu .selected-lang:before {
 content: "";
 display: inline-block;
 width: 24px;
 height: 24px;
 background-image: url(https://www.countryflags.io/us/flat/32.png);
 background-size: contain;
 background-repeat: no-repeat;
 margin-right: 5px;
}

.lang-menu select {
 z-index: 20;
 margin: 0;
 padding: 0;
 display: none;
 background-color: #fff;
 border: 1px solid #f8f8f8;
 position: absolute;
 top: 30px;
 right: 0px;
 width: auto;
 border-radius: 5px;
 box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.lang-menu select option {
 list-style: none;
 text-align: left;
 display: flex;
 justify-content: space-between;
}

.lang-menu select option p {
 text-decoration: none;
 width: 125px;
 padding: 5px 10px;
 display: block;
}

.lang-menu select option:hover {
 background-color: #f2f2f2;
}

.lang-menu select option p:before {
 content: "";
 display: inline-block;
 width: 25px;
 height: 25px;
 vertical-align: middle;
 margin-right: 10px;
 background-size: contain;
 background-repeat: no-repeat;
}

.de:before {
 background-image: url(https://www.countryflags.io/de/flat/32.png);
}

.en:before {
 background-image: url(https://www.countryflags.io/us/flat/32.png);
}
.fr:before {
 background-image: url(https://www.countryflags.io/fr/flat/32.png);
}

.ar:before {
 background-image: url(https://www.countryflags.io/ae/flat/32.png);
}

.lang-menu:hover select {
 display: block;
}
</style>
